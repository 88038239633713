<template>
  <transition-group tag="ul" name="list-slide" :class="[className, 'list-reset']">
    <li key="no-result" class="alert-container">
      <transition name="slide-down">
        <Alert v-if="companies.length === 0" :text="$t('titles.no-results-found')" />
      </transition>
    </li>
    <li v-for="(company, index) in companies" :key="index" :class="[`${className}__list-item`]">
      <a href="#" :class="[`${className}__item`]" @click.prevent="$emit('showDetail', company.data.id)">
        <div :class="[`${className}__item-column`, 'company-guide-list__item-title']">
          {{ company.data.title }}
        </div>
        <div :class="[`${className}__item-column`, 'company-guide-list__item-meta']">
          <div :class="[`${className}__item-column`, 'company-guide-list__item-city']">
            {{ company.data.visiting_address_city }}
          </div>
          <div :class="[`${className}__item-column`, 'company-guide-list__item-country']">
            {{ company.data.visiting_address_country }}
          </div>
          <div :class="[`${className}__item-column`, 'company-guide-list__item-phone']">
            {{ company.data.phone || company.data.phone_mobile }}
          </div>
        </div>
        <IconChevron />
      </a>
    </li>
  </transition-group>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import IconChevron from '../Icons/IconChevron';
import Alert from '../Alert/Alert';

export default defineComponent({
  components: {
    IconChevron,
    Alert,
  },
  props: {
    companies: {
      // eslint-disable-next-line no-undef
      type: Array as () => BAMCompanyGuide.Model.CompanyResource[],
      default: (): any => [],
    },
  },
  emits: ['showDetail'],
  data() {
    return {
      className: 'company-guide-list',
    };
  },
});
</script>

<style lang="scss" scoped>
.company-guide-list {
  position: relative;
  min-height: 72px;
  &__list-item {
    transition: all 0.2s ease-in-out;
  }

  &__item {
    background-image: linear-gradient(
      to right,
      var(--widget-color-primary--light, #{$color-primary--light}) 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 12px 1px;
    background-repeat: repeat-x;
    color: inherit;
    cursor: pointer;
    transition: background-image 0.2s ease-in-out;
    text-decoration: none;
    padding-top: var(--widget-spacer-sm, #{$spacer-sm});
    padding-right: 40px;
    padding-bottom: var(--widget-spacer-sm, #{$spacer-sm});
    position: relative;
    display: flex;
    flex-wrap: wrap;
    $company-guide-list-item-breakpoint: 450px;
    --company-guide-list-item-multiplier: calc(#{$company-guide-list-item-breakpoint} - 100%);

    &::after {
      background-color: var(--widget-color-secondary, #{$color-secondary});
      content: '';
      height: 1px;
      width: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: width 400ms ease-in-out;
    }

    &:hover,
    &:focus {
      color: var(--widget-color-secondary, #{$color-secondary});

      &::after {
        width: 100%;
      }

      svg {
        fill: var(--widget-color-secondary, #{$color-secondary});
        right: 0;
      }
    }

    &-title {
      font-weight: $font-weight-bold;
      min-width: calc(40% - 20px);
      max-width: 100%;
      flex-grow: 1;
      flex-basis: calc(var(--company-guide-list-item-multiplier) * 999);
      padding-right: 20px;
    }

    &-meta {
      display: flex;
      flex-wrap: wrap;
      min-width: 60%;
      max-width: 100%;
      flex-grow: 1;
      flex-basis: calc(var(--company-guide-list-item-multiplier) * 999);
      column-gap: 20px;
    }

    &-city,
    &-country,
    &-phone {
      max-width: 100%;
      flex-grow: 1;
      flex-basis: calc(var(--company-guide-list-item-multiplier) * 999);
    }

    &-phone {
      min-width: 180px;
    }

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(-90deg);
      transition: $transition-base;
      right: 10px;
      height: 20px;
      width: 20px;
      fill: inherit;
    }
  }
}
.list-slide-move {
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.list-slide-enter-from,
.list-slide-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.slide-down-enter-from,
.slide-down-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}
.slide-down-enter-to,
.slide-down-leave-from {
  opacity: 1;
  transform: translateY(0);
}
.slide-down-enter-active,
.slide-down-leave-active {
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.alert-container {
  .company-guide-alert {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
