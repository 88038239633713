<template>
  <section class="company-guide-section">
    <Filters />
  </section>
</template>

<script>
import Filters from '../Filters/Filters';

export default {
  components: {
    Filters,
  },
};
</script>

<style lang="scss" scoped>
.company-guide-section {
  position: sticky;
  top: 0;
  z-index: 2;
}
</style>
