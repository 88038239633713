<template>
  <article v-if="isLoaded" class="h-100">
    <div v-if="showDetailView" class="company-guide-container company-guide-container--is-full-width">
      <DetailCompanyView />
    </div>
    <div v-show="!showDetailView" class="company-guide-container">
      <HomeView />
    </div>
  </article>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import HomeView from './components/Views/Home';
import DetailCompanyView from './components/Views/Detail';

export default defineComponent({
  name: 'Widget',
  components: {
    HomeView,
    DetailCompanyView,
  },
  computed: mapState({
    isLoaded: (state) => state.companies.isLoaded,
    showDetailView: (state) => state.companies.showDetailView,
    lang: (state) => state.app.language,
  }),
  created() {
    this.$i18n.locale = this.lang ?? 'en';
    this.$store.dispatch('companies/hydrate');
    if (this.$store.state.app.viewMode === 'detail' && this.$store.state.app.rootCompanyId) {
      this.$store.dispatch('companies/addActiveCompany', this.$store.state.app.rootCompanyId);
    }
  },
});
</script>

<style lang="scss" scoped>
.h-100 {
  height: 100%;
  overflow-x: hidden;
}
</style>
