<template>
  <widget-button
    :button-classes="['company-guide-button--secondary-color', 'button--secondary-color']"
    :href="directionsUrl"
    :chevron="true"
    target="_blank"
  >
    {{ $t('titles.directions') }}
  </widget-button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import WidgetButton from '../Button/Button';

export default defineComponent({
  components: { WidgetButton },
  props: {
    street: {
      type: String,
      required: true,
    },
    zipCode: {
      type: String,
      required: true,
    },
    city: {
      type: String,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
  },
  computed: {
    directionsUrl() {
      return `https://www.google.com/maps/dir/?api=1&destination=
      ${this.street}+${this.zipCode}+${this.city}+${this.country}`;
    },
  },
});
</script>
