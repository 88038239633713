
import { defineComponent } from 'vue';
import { mapState, mapGetters } from 'vuex';

import Sidebar from '../Sidebar/Sidebar';
import List from '../List/List';
import SearchForm from '../Search/SearchForm';
import ResultsLabel from '@/components/ResultsLabel/ResultsLabel';

export default defineComponent({
  name: 'HomeView',
  components: {
    Sidebar,
    List,
    SearchForm,
    ResultsLabel,
  },
  data() {
    return {
      search: '',
      isError: false,
    };
  },
  computed: {
    ...mapState({
      isLoaded: (state) => state.companies.isLoaded,
      scrollPos: (state) => state.companies.scrollPos,
      showDetail: (state) => state.companies.showDetailView,
    }),
    ...mapGetters('companies', {
      companies: 'getCompanies',
      totalCompanies: 'getTotalCompanies',
    }),
    amountResults() {
      return this.companies.length;
    },
  },
  mounted() {
    this.scroll();
  },
  methods: {
    showDetailCompany(companyId: number): void {
      this.$store.dispatch('companies/addActiveCompany', companyId);
      this.updateScrollPosition();
    },
    updateScrollPosition() {
      const offset = window.scrollY;
      this.$store.dispatch('companies/setScrollPos', offset);
    },
    scroll() {
      window.scrollTo(0, this.scrollPos);
    },
  },
});
