import { createApp } from 'vue';
import i18n from './plugins/i18n';
import store from './store';
import Widget from '@/Widget.vue';
import api from './api';
import { IMapMarker } from '@/store/modules/app';

const rootEl = document.createElement('div');

// @todo more specific global classname for the widget i.e. something like "bamcg_widget"
// @todo do not use generic classnames for own specific styling
rootEl.className = 'company-guide-app';

// @todo check if in head; if so error
document.currentScript.parentNode.appendChild(rootEl);

// set the widget base (hosted) url (introspected from hosted js file; this file)
const resourceBaseUrl = (document.currentScript as HTMLScriptElement).src.replace(/\/js\/widget\.js.*$/, '');

// set the api base url
api.setBaseUrl(`${resourceBaseUrl}/v1`);

// bootstrap the store from the el dataset
store.dispatch('app/setLanguage', document.currentScript.dataset?.lang ?? process.env.DEFAULT_API_LANG ?? 'en');
store.dispatch('app/setRootCompanyId', parseInt(document.currentScript.dataset?.companyId ?? '450', 10));
store.dispatch('app/setViewMode', document.currentScript.dataset?.view ?? 'index');

store.dispatch('app/setMapMarker', {
  uri: document.currentScript.dataset?.mapMarkerUri ?? `${resourceBaseUrl}/img/map-marker.gif`,
  offsetX: parseFloat(document.currentScript.dataset?.mapMarkerOffsetX ?? '0.51'),
  offsetY: parseFloat(document.currentScript.dataset?.mapMarkerOffsetY ?? '0.8'),
  displayActualPoint:
    process.env.NODE_ENV === 'development' || document.currentScript.dataset?.mapMarkerDisplayActualPoint === '1',
} as IMapMarker);

store.dispatch('app/setInitialized', true);

// create the app ;-)
createApp(Widget).use(i18n).use(store).mount(rootEl);
