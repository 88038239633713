
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'Alert',
  props: {
    title: {
      required: false,
      type: String as PropType<string>,
    },
    text: {
      required: true,
      type: String as PropType<string>,
    },
    buttonClasses: {
      type: Array as PropType<string[]>,
      required: false,
    },
  },
  computed: {
    classes(): Array<string> {
      const classes: Array<string> = ['company-guide-alert', 'company-guide-bg-support--lighter'];
      return classes.concat(this.buttonClasses);
    },
  },
});
