import { createStore } from 'vuex';

import app, { IState as AppModuleState } from './modules/app';
import companies, { IState as CompanyModuleState } from './modules/companies';

export interface RootState {
  app: AppModuleState;
  companies: CompanyModuleState;
}

export default createStore<RootState>({
  strict: true,
  modules: {
    app,
    companies,
  },
});
