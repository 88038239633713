
import { defineComponent, PropType } from 'vue';
import View from 'ol/View';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { Zoom } from 'ol/control';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import { Style, Icon } from 'ol/style';
import { transform, fromLonLat } from 'ol/proj';
import { Vector as SourceVector } from 'ol/source';
import { Vector as LayerVector } from 'ol/layer';
import { defaults } from 'ol/interaction';
import { IMapMarker } from '@/store/modules/app';

export default defineComponent({
  props: {
    lat: {
      required: true,
      type: Number as PropType<number>,
    },
    long: {
      required: true,
      type: Number as PropType<number>,
    },
  },
  data() {
    return {
      map: null as any,
      zoom: 16 as number,
      view: null as any,
      layer: null as any,
    };
  },
  computed: {
    isValidLatLong() {
      return this.long != null && this.lat != null;
    },
  },
  updated() {
    this.$nextTick(function updateMap() {
      this.forceUpdate();
    });
  },
  mounted() {
    this.initMap();
  },
  methods: {
    forceUpdate() {
      this.view.setCenter(fromLonLat([this.long, this.lat]));
      this.setMarker();
    },
    setView() {
      this.view = new View({
        center: fromLonLat([this.long, this.lat]),
        zoom: this.zoom,
        maxZoom: 18,
        minZoom: 3,
      });
    },
    initMap() {
      if (!this.isValidLatLong) {
        return;
      }
      this.createMap();
      this.setMarker();
    },
    createMap() {
      this.setView();
      const zoomControl = new Zoom();
      this.map = new Map({
        interactions: defaults({ mouseWheelZoom: false }),
        target: this.$refs['bam-companyguide-widget-map'] as HTMLElement,
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
        ],
        controls: [zoomControl],
        view: this.view,
      });
    },
    setMarker() {
      const mapMarker = this.$store.getters['app/getMapMarker'] as IMapMarker;

      this.removeLastLayer();

      const features = [];

      const geometry = new Point(transform([this.long, this.lat], 'EPSG:4326', 'EPSG:3857'));

      if (mapMarker.displayActualPoint) {
        // display the actual point as a (default) map marker in dev
        features.push(new Feature({ geometry }));
      }

      // display the custom map marker
      const iconFeature = new Feature({ geometry });
      iconFeature.setStyle(
        new Style({
          image: new Icon({
            anchor: [mapMarker.offsetX, mapMarker.offsetY],
            src: mapMarker.uri,
          }),
        })
      );
      features.push(iconFeature);

      this.layer = new LayerVector({
        source: new SourceVector({
          features,
        }),
      });
      this.map.addLayer(this.layer);
    },
    removeLastLayer() {
      if (this.map.getLayers().getLength() > 1) {
        this.map.getLayers().pop();
      }
    },
  },
});
