
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'ResultsLabel',
  props: {
    count: {
      required: true,
      type: Number as PropType<number>,
    },
    total: {
      required: true,
      type: Number as PropType<number>,
    },
  },
  computed: {
    compact() {
      return this.count === this.total;
    },
  },
});
