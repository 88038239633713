<template>
  <form class="company-widget-search-form">
    <SearchInput
      v-model:value="activeFilters.search"
      class="company-widget-search-form__input"
      :class="searchEmpty ? 'company-widget-search-form__input--is-empty' : ''"
      :placeholder="$t('form.search-input-placeholder')"
      @inputQuery="updateSearch($event)"
    />
    <div class="company-widget-search-form__actions">
      <transition name="fade">
        <button
          v-if="!searchEmpty"
          class="company-widget-search-form__button company-widget-search-form__clear"
          type="reset"
          @click="resetSearch"
        >
          <IconCross />
        </button>
      </transition>
      <span class="company-widget-search-form__button company-widget-search-form__search-icon">
        <IconSearch />
        <span v-t="'form.search'" class="visually-hidden"></span>
      </span>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import SearchInput from '../Forms/Input';
import IconSearch from '../Icons/IconSearch';
import IconCross from '../Icons/IconCross';

export default {
  components: {
    SearchInput,
    IconSearch,
    IconCross,
  },
  data() {
    return {
      localFilters: {
        search: '',
      },
    };
  },
  computed: {
    ...mapState({
      filters: (state) => state.companies.filters,
    }),
    activeFilters: {
      get() {
        Object.assign(this.localFilters, this.filters);
        return this.localFilters;
      },
      set(filters) {
        this.localFilters = filters;
      },
    },
    searchEmpty() {
      return this.filters.search === '';
    },
  },
  methods: {
    updateSearch(event) {
      this.$store.dispatch('companies/filterCompanies', { search: event });
    },
    resetSearch() {
      this.$store.dispatch('companies/resetSearch');
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
