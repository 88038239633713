<template>
  <h2 v-t="'titles.filter-results'"></h2>
  <div class="filters">
    <Select
      id="division"
      v-model:selected="activeFilters.division"
      class="filters__item"
      :items="divisions"
      :title="$t('titles.division')"
      @change="updateFilter"
    />
    <Select
      id="city"
      v-model:selected="activeFilters.city"
      class="filters__item"
      :items="cities"
      :title="$t('titles.city')"
      @change="updateFilter"
    />
    <Select
      id="country"
      v-model:selected="activeFilters.country"
      class="filters__item"
      :items="countries"
      :title="$t('titles.country')"
      @change="updateFilter"
    />
    <transition name="fade">
      <div v-if="!filtersEmpty" class="filters__actions">
        <ResetButton :link-classes="blockLinkResetClasses" :cross="true" @click="resetFilter">
          {{ $t('form.clear-filters') }}
        </ResetButton>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import Select from '../Forms/Select';
import ResetButton from '../Link/BlockLink';

export default defineComponent({
  components: {
    Select,
    ResetButton,
  },
  data() {
    return {
      localFilters: {
        division: '',
        city: '',
        country: '',
      },
      blockLinkResetClasses: ['block-link--is-ghost', 'block-link--icon-small'],
    };
  },
  computed: {
    ...mapGetters('companies', {
      divisions: 'getDivisions',
      countries: 'getCountries',
      cities: 'getCitiesByCountry',
    }),
    ...mapState({
      filters: (state) => state.companies.filters,
    }),
    activeFilters: {
      get() {
        Object.assign(this.localFilters, this.filters);
        return this.localFilters;
      },
      set(filters) {
        this.localFilters = filters;
      },
    },
    filtersEmpty() {
      return this.filters.division === '' && this.filters.city === '' && this.filters.country === '';
    },
  },
  methods: {
    updateFilter() {
      this.$store.dispatch('companies/filterCompanies', this.localFilters);
    },
    resetFilter() {
      this.$store.dispatch('companies/resetFilter');
    },
  },
});
</script>

<style lang="scss" scoped>
.filters {
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--widget-spacer, #{$spacer});
  row-gap: var(--widget-spacer-sm, #{$spacer-sm});
  justify-content: flex-end;
  &__item {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 200px;
    max-width: 100%;
  }
  &__actions {
    width: 100%;
    text-align: right;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
