
import { defineComponent, PropType } from 'vue';

import IconChevron from '../Icons/IconChevron';
import IconCross from '../Icons/IconCross';

export default defineComponent({
  name: 'BlockLink',
  components: {
    IconChevron,
    IconCross,
  },
  props: {
    href: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
    linkClasses: {
      type: Array as PropType<string[]>,
      required: false,
    },
    chevron: {
      required: false,
      type: Boolean as PropType<boolean>,
    },
    cross: {
      required: false,
      type: Boolean as PropType<boolean>,
    },
  },
  computed: {
    type() {
      return this.href ? 'a' : 'button';
    },
    classes(): Array<string> {
      const classes: Array<string> = ['block-link'];
      return classes.concat(this.linkClasses);
    },
  },
});
