
import { defineComponent } from 'vue';

import IconChevron from '../Icons/IconChevron';
import Alert from '../Alert/Alert';

export default defineComponent({
  components: {
    IconChevron,
    Alert,
  },
  props: {
    companies: {
      // eslint-disable-next-line no-undef
      type: Array as () => BAMCompanyGuide.Model.CompanyResource[],
      default: (): any => [],
    },
  },
  emits: ['showDetail'],
  data() {
    return {
      className: 'company-guide-list',
    };
  },
});
