
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'Input',
  props: {
    placeholder: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    value: {
      required: false,
      type: String as PropType<string>,
    },
  },
  emits: ['inputQuery'],
  data() {
    return {
      formValue: '',
    };
  },
  computed: {
    localInputValue: {
      get() {
        return this.value;
      },
      set(value: string) {
        this.formValue = value;
      },
    },
  },
});
