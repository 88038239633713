<template>
  <div :class="classes" role="alert">
    <h3 v-if="title">{{ title }}</h3>
    <div>{{ text }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'Alert',
  props: {
    title: {
      required: false,
      type: String as PropType<string>,
    },
    text: {
      required: true,
      type: String as PropType<string>,
    },
    buttonClasses: {
      type: Array as PropType<string[]>,
      required: false,
    },
  },
  computed: {
    classes(): Array<string> {
      const classes: Array<string> = ['company-guide-alert', 'company-guide-bg-support--lighter'];
      return classes.concat(this.buttonClasses);
    },
  },
});
</script>

<style lang="scss" scoped>
.company-guide-alert {
  border: 1px dashed var(--widget-color-support, #{$color-support});
  padding: var(--widget-spacer, #{$spacer});
  text-align: center;
  width: 100%;
}
</style>
