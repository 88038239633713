<template>
  <div class="company-guide-result-label">
    <div v-if="compact" v-t="{ path: 'form.found-results-compact', args: { total } }"></div>
    <div v-else v-t="{ path: 'form.found-results', args: { count, total } }"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'ResultsLabel',
  props: {
    count: {
      required: true,
      type: Number as PropType<number>,
    },
    total: {
      required: true,
      type: Number as PropType<number>,
    },
  },
  computed: {
    compact() {
      return this.count === this.total;
    },
  },
});
</script>

<style lang="scss" scoped>
.company-guide-result-label {
  color: var(--widget-color-support, #{$color-support});
  text-align: right;
  font-size: 90%;
}
</style>
