
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import BlockLink from '../Link/BlockLink';
import OpenMap from '../Map/Map';
import SubCompanies from '../SubCompanies/SubCompanies';
import DirectionsButton from '../DirectionsButton/DirectionsButton';
import BuildingImage from '../Image/Image';

export default defineComponent({
  name: 'DetailView',
  components: {
    BlockLink,
    OpenMap,
    SubCompanies,
    DirectionsButton,
    BuildingImage,
  },
  computed: {
    ...mapState({
      canGoBack: (state) =>
        !(
          state.app.viewMode === 'detail' &&
          state.app.rootCompanyId === state.companies.activeCompanies[state.companies.activeCompanies.length - 1]
        ),
      subcompaniesLoaded: (state) => state.companies.subCompaniesLoaded,
    }),
    ...mapGetters('companies', {
      company: 'getActiveCompany',
    }),
    parentCompanyName(): string {
      const parentCompany = this.$store.state.companies.all.find(
        (company) => company.data.id === this.company.data.parent_id
      );
      return parentCompany ? parentCompany.data.title : '-';
    },
    previousLabel(): string {
      return this.$store.state.companies.activeCompanies.length >= 2
        ? `${this.$t('navigation.back-to')} ${this.$store.getters['companies/getPreviousActiveCompany'].data.title}`
        : this.$t('navigation.back-to-overview');
    },
    isValidPostalAddress() {
      return (
        !!this.company.data.postal_address_line_1 &&
        !!this.company.data.postal_address_zipcode &&
        !!this.company.data.postal_address_city
      );
    },
  },
  methods: {
    goBack(): void {
      this.$store.dispatch('companies/hideActiveDetailCompany');
    },
  },
});
