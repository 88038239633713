
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import List from '../List/List';

export default defineComponent({
  components: { List },
  props: {
    companyId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters('companies', {
      subCompanies: 'getSubCompanies',
    }),
  },
  methods: {
    showDetail(companyId: number) {
      this.$store.dispatch('companies/addActiveCompany', companyId);
      this.scrollToTop();
    },
    scrollToTop(): void {
      window.scrollTo(0, 0);
    },
  },
});
