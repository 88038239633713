
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import HomeView from './components/Views/Home';
import DetailCompanyView from './components/Views/Detail';

export default defineComponent({
  name: 'Widget',
  components: {
    HomeView,
    DetailCompanyView,
  },
  computed: mapState({
    isLoaded: (state) => state.companies.isLoaded,
    showDetailView: (state) => state.companies.showDetailView,
    lang: (state) => state.app.language,
  }),
  created() {
    this.$i18n.locale = this.lang ?? 'en';
    this.$store.dispatch('companies/hydrate');
    if (this.$store.state.app.viewMode === 'detail' && this.$store.state.app.rootCompanyId) {
      this.$store.dispatch('companies/addActiveCompany', this.$store.state.app.rootCompanyId);
    }
  },
});
