import { createI18n } from 'vue-i18n';

import da from '../locales/da.json';
import de from '../locales/de.json';
import en from '../locales/en.json';
import fr from '../locales/fr.json';
import nl from '../locales/nl.json';

export default createI18n({
  locale: process.env.API_DEFAULT_LANG ?? 'en', // Active language
  fallbackLocale: process.env.API_DEFAULT_LANG ?? 'en', // set fallback locale
  messages: {
    da,
    de,
    en,
    fr,
    nl,
  },
});
