<template>
  <img v-if="loaded && !error" :src="url" :alt="altText" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import api from '../../api';

export default defineComponent({
  props: {
    companyId: {
      required: true,
      type: Number,
    },
    altText: {
      required: true,
      type: String,
    },
    width: {
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      baseUrl: '',
      error: false,
      imageUrl: '',
      loaded: false,
    };
  },
  computed: {
    url(): string {
      return `${this.baseUrl}${this.imageUrl}`;
    },
  },
  created() {
    api
      .getBuildingImage(this.companyId, this.width)
      .then((response) => {
        if (response.status === 200) {
          this.baseUrl = response.config.baseURL;
          this.imageUrl = response.config.url;
          this.loaded = true;
        }
      })
      .catch(() => {
        this.error = true;
      });
  },
});
</script>
