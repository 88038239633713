
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import Select from '../Forms/Select';
import ResetButton from '../Link/BlockLink';

export default defineComponent({
  components: {
    Select,
    ResetButton,
  },
  data() {
    return {
      localFilters: {
        division: '',
        city: '',
        country: '',
      },
      blockLinkResetClasses: ['block-link--is-ghost', 'block-link--icon-small'],
    };
  },
  computed: {
    ...mapGetters('companies', {
      divisions: 'getDivisions',
      countries: 'getCountries',
      cities: 'getCitiesByCountry',
    }),
    ...mapState({
      filters: (state) => state.companies.filters,
    }),
    activeFilters: {
      get() {
        Object.assign(this.localFilters, this.filters);
        return this.localFilters;
      },
      set(filters) {
        this.localFilters = filters;
      },
    },
    filtersEmpty() {
      return this.filters.division === '' && this.filters.city === '' && this.filters.country === '';
    },
  },
  methods: {
    updateFilter() {
      this.$store.dispatch('companies/filterCompanies', this.localFilters);
    },
    resetFilter() {
      this.$store.dispatch('companies/resetFilter');
    },
  },
});
