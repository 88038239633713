<template>
  <component :is="type" :href="href" :class="classes">
    <IconCross v-if="cross" />
    <IconChevron v-if="chevron" />
    <span><slot /></span>
  </component>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import IconChevron from '../Icons/IconChevron';
import IconCross from '../Icons/IconCross';

export default defineComponent({
  name: 'BlockLink',
  components: {
    IconChevron,
    IconCross,
  },
  props: {
    href: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
    linkClasses: {
      type: Array as PropType<string[]>,
      required: false,
    },
    chevron: {
      required: false,
      type: Boolean as PropType<boolean>,
    },
    cross: {
      required: false,
      type: Boolean as PropType<boolean>,
    },
  },
  computed: {
    type() {
      return this.href ? 'a' : 'button';
    },
    classes(): Array<string> {
      const classes: Array<string> = ['block-link'];
      return classes.concat(this.linkClasses);
    },
  },
});
</script>

<style lang="scss" scoped>
.block-link {
  background: transparent;
  border: none;
  color: var(--widget-color-secondary, #{$color-secondary});
  position: relative;
  display: inline-flex;
  text-decoration: none;
  padding-left: 20px;
  transition: color 150ms ease-in-out;

  &::after {
    background-color: var(--widget-color-secondary, #{$color-secondary});
    content: '';
    height: 1px;
    width: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    transition: width 150ms ease-in-out;
  }

  &:hover,
  &:focus {
    color: var(--widget-color-secondary--dark, #{$color-secondary--dark});
    &::after {
      background-color: var(--widget-color-secondary--dark, #{$color-secondary--dark});
      width: 100%;
    }

    svg {
      fill: var(--widget-color-secondary--dark, #{$color-secondary--dark});
    }
  }
  &--icon-hover-left {
    &:hover,
    &:focus {
      svg {
        left: -4px;
      }
    }
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    left: 0;
    height: 16px;
    width: 16px;
    fill: var(--widget-color-secondary, #{$color-secondary});
    transition: left 150ms ease-in-out;
  }

  &--is-ghost {
    color: #fff;

    &::after {
      background-color: #fff;
    }

    &:hover,
    &:focus {
      color: #fff;
      &::after {
        background-color: #fff;
      }

      svg {
        fill: #fff;
      }
    }

    svg {
      fill: #fff;
    }
  }

  &--show-icon-after {
    padding-left: 0;
    padding-right: 20px;

    &::after {
      left: 0;
      right: inherit;
    }

    svg {
      transform: translateY(-50%) rotate(-90deg);
      transition-property: right;
      left: inherit;
      right: 0;
    }

    &:hover,
    &:focus {
      svg {
        right: -4px;
        left: inherit;
      }
    }
  }
  &--icon-small {
    svg {
      height: 10px;
      width: 10px;
    }
  }
}
</style>
