
import { defineComponent, PropType } from 'vue';

import IconChevron from '../Icons/IconChevron';

export default defineComponent({
  name: 'WidgetButton',
  components: {
    IconChevron,
  },
  props: {
    href: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
    buttonClasses: {
      type: Array as PropType<string[]>,
      required: false,
    },
    chevron: {
      type: Boolean as PropType<boolean>,
      required: true,
      default: true,
    },
  },
  computed: {
    type() {
      return this.href ? 'a' : 'button';
    },
    classes(): Array<string> {
      const classes: Array<string> = ['company-guide-button', 'button'];
      return classes.concat(this.buttonClasses);
    },
  },
});
