<template>
  <aside v-if="isLoaded" class="company-guide-sidebar company-guide-bg-primary">
    <sidebar />
  </aside>
  <section v-if="isLoaded" class="company-guide-main">
    <section class="company-guide-section">
      <div class="company-guide-section__top">
        <SearchForm v-model:query="search" :query="search" />
      </div>

      <div class="company-guide-section__main">
        <ResultsLabel :total="totalCompanies" :count="amountResults" />
        <List :companies="companies" @showDetail="showDetailCompany" />
      </div>
    </section>
  </section>
  <transition name="company-guide-fade">
    <div v-if="!isLoaded" class="company-guide-loader company-guide-loader--has-spinner">
      <span class="company-guide-loader__spinner"></span>
    </div>
  </transition>
  <transition name="company-guide-fade">
    <div v-if="showDetail" class="company-guide-loader" />
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapGetters } from 'vuex';

import Sidebar from '../Sidebar/Sidebar';
import List from '../List/List';
import SearchForm from '../Search/SearchForm';
import ResultsLabel from '@/components/ResultsLabel/ResultsLabel';

export default defineComponent({
  name: 'HomeView',
  components: {
    Sidebar,
    List,
    SearchForm,
    ResultsLabel,
  },
  data() {
    return {
      search: '',
      isError: false,
    };
  },
  computed: {
    ...mapState({
      isLoaded: (state) => state.companies.isLoaded,
      scrollPos: (state) => state.companies.scrollPos,
      showDetail: (state) => state.companies.showDetailView,
    }),
    ...mapGetters('companies', {
      companies: 'getCompanies',
      totalCompanies: 'getTotalCompanies',
    }),
    amountResults() {
      return this.companies.length;
    },
  },
  mounted() {
    this.scroll();
  },
  methods: {
    showDetailCompany(companyId: number): void {
      this.$store.dispatch('companies/addActiveCompany', companyId);
      this.updateScrollPosition();
    },
    updateScrollPosition() {
      const offset = window.scrollY;
      this.$store.dispatch('companies/setScrollPos', offset);
    },
    scroll() {
      window.scrollTo(0, this.scrollPos);
    },
  },
});
</script>

<style lang="scss" scoped>
.company-guide {
  &-sidebar {
    flex-basis: $sidebar-width;
    flex-grow: 1;
  }

  &-main {
    flex-basis: 0;
    flex-grow: 999;
    min-width: 74%;
    min-height: 100%;
  }
}
</style>
