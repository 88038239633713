<template>
  <section v-if="company" class="company-guide-section">
    <section v-if="canGoBack" class="company-guide-section__top">
      <BlockLink :chevron="true" href="#" :link-classes="['block-link--icon-hover-left']" @click.prevent="goBack">
        {{ previousLabel }}
      </BlockLink>
    </section>
    <section class="company-guide-section__main">
      <transition name="company-guide-fade">
        <div v-if="!subcompaniesLoaded" class="company-guide-loader" />
      </transition>
      <div class="company-guide-contact-header">
        <div class="company-guide-contact-meta">
          <h2>{{ company.data.title }}</h2>
          <p>
            <span v-if="company.data.phone">
              T:
              <a :href="`tel:${company.data.phone}`">{{ company.data.phone }}</a> <br />
            </span>
            <span v-if="company.data.phone_mobile">
              M:
              <a v-if="company.data.phone_mobile" :href="`tel:${company.data.phone_mobile}`">
                {{ company.data.phone_mobile }}
              </a>
              <br />
            </span>
            <span v-if="company.data.email">
              E:
              <a v-if="company.data.email" :href="`mailto:${company.data.email}`">{{ company.data.email }}</a>
              <br />
            </span>
            <span v-if="company.data.website_url">
              W:
              <a v-if="company.data.website_url" :href="`${company.data.website_url}`" target="_blank">{{ company.data.website_title }}</a>
              <br />
            </span>
          </p>
        </div>
        <BuildingImage
          v-if="company.data.building_image_name"
          :company-id="company.data.id"
          :alt-text="company.data.building_image_name"
        />
      </div>
      <div class="company-guide-contact-information">
        <OpenMap :lat="company.data.visiting_address_lat" :long="company.data.visiting_address_lng" />
        <div
          :class="[
            'company-guide-contact-details-container',
            company.data.visiting_address_lat ? 'company-guide-contact-details-container--has-map' : '',
          ]"
        >
          <div class="company-guide-contact-details">
            <div class="company-guide-contact-details__item">
              <h3 v-t="'titles.visiting-address'"></h3>
              <span v-if="company.data.visiting_address_line_1">
                {{ company.data.visiting_address_line_1 }}
                <br />
              </span>
              <span v-if="company.data.visiting_address_line_2">
                {{ company.data.visiting_address_line_2 }}
                <br />
              </span>
              <template v-if="['GB', 'IE'].includes(company.data.visiting_address_country_code)">
                <span v-if="company.data.visiting_address_city">
                {{ company.data.visiting_address_city }}
                <br />
                </span>
                <span v-if="company.data.visiting_address_zipcode">
                {{ company.data.visiting_address_zipcode }}
                <br />
                </span>
              </template>
              <template v-else>
                <span v-if="company.data.visiting_address_zipcode || company.data.visiting_address_city">
                {{ company.data.visiting_address_zipcode }} {{ company.data.visiting_address_city }}
                <br />
                </span>
              </template>
              <span v-if="company.data.visiting_address_country">
                {{ company.data.visiting_address_country }}
                <br />
              </span>
            </div>
            <div v-if="isValidPostalAddress" class="company-guide-contact-details__item">
              <h3 v-t="'titles.postal-address'"></h3>
              <span v-if="company.data.postal_address_line_1">
                {{ company.data.postal_address_line_1 }}
                <br />
              </span>
              <span v-if="company.data.postal_address_line_2">
                {{ company.data.postal_address_line_2 }}
                <br />
              </span>
              <template v-if="['GB', 'IE'].includes(company.data.postal_address_country_code)">
                <span v-if="company.data.postal_address_city">
                {{ company.data.postal_address_city }}
                <br />
                </span>
                <span v-if="company.data.postal_address_zipcode">
                {{ company.data.postal_address_zipcode }}
                <br />
                </span>
              </template>
              <template v-else>
                <span v-if="company.data.postal_address_zipcode || company.data.postal_address_city">
                {{ company.data.postal_address_zipcode }} {{ company.data.postal_address_city }}
                <br />
                </span>
              </template>
              <span v-if="company.data.postal_address_country">
                {{ company.data.postal_address_country }}
                <br />
              </span>
            </div>
            <div
              v-if="parentCompanyName || company.data.division || company.data.trade_register_nr"
              class="company-guide-contact-details__item"
            >
              <h3 v-t="'titles.company-data'"></h3>
              <p>
                <span v-if="parentCompanyName">
                  <span v-t="'titles.part-of'" class="company-guide-label"></span>: {{ parentCompanyName }} <br />
                </span>
                <span v-if="company.data.division">
                  <span v-t="'titles.division'" class="company-guide-label"></span>:
                  {{ company.data.division }}
                  <br />
                </span>
                <span v-if="company.data.trade_register_nr">
                  <span v-t="'titles.trade-register'" class="company-guide-label"></span>:
                  {{ company.data.trade_register_nr }}
                  <br />
                </span>
              </p>
            </div>
          </div>
          <directions-button
            :street="company.data.visiting_address_line_1"
            :zip-code="company.data.visiting_address_zipcode"
            :city="company.data.visiting_address_city"
            :country="company.data.visiting_address_country"
          />
          <div v-if="company.data.activity" class="company-guide-contact-activity">
            <h3 v-t="'titles.company-activities'"></h3>
            <p>{{ company.data.activity }}</p>
          </div>
        </div>
      </div>
      <SubCompanies :company-id="company.data.id" />
    </section>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import BlockLink from '../Link/BlockLink';
import OpenMap from '../Map/Map';
import SubCompanies from '../SubCompanies/SubCompanies';
import DirectionsButton from '../DirectionsButton/DirectionsButton';
import BuildingImage from '../Image/Image';

export default defineComponent({
  name: 'DetailView',
  components: {
    BlockLink,
    OpenMap,
    SubCompanies,
    DirectionsButton,
    BuildingImage,
  },
  computed: {
    ...mapState({
      canGoBack: (state) =>
        !(
          state.app.viewMode === 'detail' &&
          state.app.rootCompanyId === state.companies.activeCompanies[state.companies.activeCompanies.length - 1]
        ),
      subcompaniesLoaded: (state) => state.companies.subCompaniesLoaded,
    }),
    ...mapGetters('companies', {
      company: 'getActiveCompany',
    }),
    parentCompanyName(): string {
      const parentCompany = this.$store.state.companies.all.find(
        (company) => company.data.id === this.company.data.parent_id
      );
      return parentCompany ? parentCompany.data.title : '-';
    },
    previousLabel(): string {
      return this.$store.state.companies.activeCompanies.length >= 2
        ? `${this.$t('navigation.back-to')} ${this.$store.getters['companies/getPreviousActiveCompany'].data.title}`
        : this.$t('navigation.back-to-overview');
    },
    isValidPostalAddress() {
      return (
        !!this.company.data.postal_address_line_1 &&
        !!this.company.data.postal_address_zipcode &&
        !!this.company.data.postal_address_city
      );
    },
  },
  methods: {
    goBack(): void {
      this.$store.dispatch('companies/hideActiveDetailCompany');
    },
  },
});
</script>

<style lang="scss" scoped>
.company-guide {
  &-contact-header {
    display: flex;
    flex-wrap: wrap;
    column-gap: var(--widget-spacer, #{$spacer});
    margin-bottom: var(--widget-spacer-md, #{$spacer-md});
    justify-content: space-between;
  }

  &-contact-details {
    display: flex;
    flex-wrap: wrap;
    --multiplier: calc(650px - 100%);
    --column-gap: var(--widget-spacer, #{$spacer});
    column-gap: calc(var(--column-gap) * -1);
    row-gap: var(--column-gap);
    margin-bottom: var(--column-gap);

    &-container {
      &--has-map {
        background-color: #fff;
        padding: var(--widget-spacer, #{$spacer});
        position: relative;
        margin: var(--widget-spacer, #{$spacer});
        margin-top: -180px;

        @include media-breakpoint-up(md) {
          padding: var(--widget-spacer-md, #{$spacer-md});
          margin-left: var(--widget-spacer-lg, #{$spacer-lg});
          margin-right: var(--widget-spacer-lg, #{$spacer-lg});
        }
      }
    }

    &__item {
      min-width: 33%;
      max-width: 400px;
      flex-grow: 1;
      flex-basis: calc(var(--multiplier) * 999);

      &:nth-child(2n - 1) {
        min-width: calc(30% - (var(--column-gap) * 2));
      }

      &:nth-child(2n) {
        min-width: calc(30% - (var(--column-gap) * 2));
      }
    }
  }

  &-contact-activity {
    margin-top: var(--widget-spacer, #{$spacer});
  }

  &-label {
    font-weight: $font-weight-bold;
  }
}
</style>
