<template>
  <div class="company-guide-form-element">
    <label :for="id" class="h3">{{ title }}</label>
    <div class="company-guide-select-wrapper">
      <select :id="id" v-model="localSelectedInput" class="company-guide-form-control" @change="updateInput">
        <option v-t="'form.all'" value="" selected></option>
        <option v-for="(item, index) in items" :key="index" :value="item.key">
          {{ item.value }}
        </option>
      </select>
      <IconChevron />
    </div>
  </div>
</template>

<script>
import IconChevron from '../Icons/IconChevron';

export default {
  components: { IconChevron },
  props: {
    title: String,
    items: Array,
    id: String,
    selected: String,
  },
  emits: ['update:selected'],
  data() {
    return {
      localSelected: this.selected,
    };
  },
  computed: {
    localSelectedInput: {
      get() {
        return this.selected;
      },
      set(value) {
        this.localSelected = value;
      },
    },
  },
  methods: {
    updateInput() {
      this.$emit('update:selected', this.localSelected);
    },
  },
};
</script>

<style lang="scss" scoped>
.company-guide-select-wrapper {
  position: relative;
  select {
    padding-right: var(--widget-spacer-md, #{$spacer-md});
  }
  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    height: 20px;
    width: 20px;
    fill: var(--widget-color-body, #{$body-color});
  }
}
</style>
