import { GetterTree, MutationTree, ActionTree, Module } from 'vuex';
import i18n from '../../plugins/i18n';

type ViewModeType = 'index' | 'detail';

export interface IMapMarker {
  uri?: string;
  offsetX: number;
  offsetY: number;
  displayActualPoint?: boolean;
}

export interface IState {
  initialized: boolean;
  language: string;
  rootCompanyId: number;
  viewMode: ViewModeType;
  mapMarker?: IMapMarker;
}

// initial state
const state = (): IState => ({
  initialized: false,
  language: process.env.API_DEFAULT_LANG ?? 'en',
  rootCompanyId: 450,
  viewMode: 'index',
  mapMarker: {
    uri: '/img/map-marker.gif',
    offsetX: 0.51,
    offsetY: 0.8,
  },
});

// getters
const getters = <GetterTree<IState, any>>{
  getLanguage(state): string {
    return state.language;
  },
  getRootCompanyId(state): number {
    return state.rootCompanyId;
  },
  getViewMode(state): ViewModeType {
    return state.viewMode;
  },
  getMapMarker(state): IMapMarker {
    return state.mapMarker;
  },
};

// actions
const actions = <ActionTree<IState, any>>{
  setLanguage({ commit }, language: string) {
    commit('setLanguage', language);
    // @ts-ignore
    i18n.locale = language;
  },
  setRootCompanyId({ commit }, rootCompanyId: number | string) {
    commit('setRootCompanyId', rootCompanyId);
  },
  setInitialized({ commit }, initialized: boolean) {
    commit('setInitialized', initialized);
  },
  setViewMode({ commit }, viewMode: ViewModeType) {
    commit('setViewMode', viewMode);
  },
  setMapMarker({ commit }, mapMarker: IMapMarker) {
    commit('setMapMarker', mapMarker);
  },
};

// mutations
const mutations = <MutationTree<IState>>{
  setLanguage(state, language: string) {
    state.language = language;
  },
  setRootCompanyId(state, companyId: number) {
    state.rootCompanyId = companyId;
  },
  setInitialized(state, initialized: boolean) {
    state.initialized = initialized;
  },
  setViewMode(state, viewMode: ViewModeType) {
    state.viewMode = viewMode;
  },
  setMapMarker(state, mapMarker: IMapMarker) {
    state.mapMarker = mapMarker;
  },
};

export default <Module<IState, any>>{
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
