import Axios from 'axios';

const http = Axios.create({});

const getCompanies = async (
  lang = 'en',
  limit = 1000,
  sort = 'title'
): Promise<BAMCompanyGuide.Model.CompanyResource[]> => {
  return (
    await http.get<BAMCompanyGuide.Model.CompanyCollection>(`/companies?sort=${sort}&limit=${limit}&lang=${lang}`)
  )?.data?.data;
};

const getSubCompanies = async (
  lang = 'en',
  companyId = 0,
  limit = 1000,
  sort = 'title'
): Promise<BAMCompanyGuide.Model.CompanyResource[]> => {
  return (
    await http.get<BAMCompanyGuide.Model.CompanyCollection>(
      `/companies/${companyId}/children?sort=${sort}&limit=${limit}&lang=${lang}`
    )
  )?.data?.data;
};

const getBuildingImage = async (companyId = 0, width = 400, height = 400, format = 'auto') => {
  return http.get(`/companies/${companyId}/building_image?format=${format}&w=${width}&h=${height}`);
};

const setBaseUrl = (baseUrl: string) => {
  http.defaults.baseURL = baseUrl;
};

export default { getCompanies, getSubCompanies, getBuildingImage, setBaseUrl };
