import { ActionTree, GetterTree, MutationTree } from 'vuex';
import api from '../../api';

interface IFilters {
  city: string;
  country: string;
  search: string;
  division: string;
}

export interface IState {
  isLoaded: boolean;
  all: BAMCompanyGuide.Model.CompanyResource[];
  filtered: BAMCompanyGuide.Model.CompanyResource[];
  subCompanies: BAMCompanyGuide.Model.CompanyResource[];
  error?: Error;
  filters: IFilters;
  rootCompanyId: number;
  showDetailView: boolean;
  subCompaniesLoaded: boolean;
  activeCompanies: Array<number>;
  scrollPos: number;
}

interface IKeyValuePair {
  key: string;
  value: string;
}

// initial state
const state = (): IState => ({
  isLoaded: false,
  all: [],
  filtered: [],
  filters: {
    division: '',
    search: '',
    country: '',
    city: '',
  },
  error: null,
  rootCompanyId: null,
  showDetailView: false,
  subCompaniesLoaded: false,
  subCompanies: [],
  activeCompanies: [],
  scrollPos: 0,
});

const getSortedArrayOfProperty = (
  companies: BAMCompanyGuide.Model.CompanyResource[],
  property: string,
  valueProperty: string = null
): IKeyValuePair[] => {
  return companies
    .reduce<IKeyValuePair[]>((items, company) => {
      if (!company.data[property] || items.findIndex((c) => c.key === company.data[property]) !== -1) {
        return items;
      }
      items.push({
        key: company.data[property],
        value: company.data[valueProperty ?? property],
      });
      return items;
    }, [])
    .sort((a, b) => a.value.localeCompare(b.value));
};

const filterCompanies = (filters: IFilters, companies: BAMCompanyGuide.Model.CompanyResource[]) => {
  let filteredList = [...companies];
  if (filters.division != null && filters.division !== '') {
    filteredList = filteredList.filter((company) => {
      const companyDivision = company.data.division;
      return companyDivision != null && companyDivision.toLowerCase().includes(filters.division.toLowerCase());
    });
  }
  if (filters.country !== '' && filters.country !== undefined) {
    filteredList = filteredList.filter((company) =>
      company.data.visiting_address_country_code.toLowerCase().includes(filters.country.toLowerCase())
    );
  }
  if (filters.city !== '' && filters.city !== undefined) {
    filteredList = filteredList.filter((company) =>
      company.data.visiting_address_city.toLowerCase().includes(filters.city.toLowerCase())
    );
  }
  if (filters.search !== '' && filters.search !== undefined) {
    filteredList = filteredList.filter((company) =>
      `${company.data.title}${company.data.visiting_address_city}${company.data.visiting_address_country}`
        .toLowerCase()
        .includes(filters.search.toLowerCase())
    );
  }
  return filteredList;
};

// getters
const getters = <GetterTree<IState, any>>{
  isLoaded(state): boolean {
    return state.isLoaded;
  },
  getCompanies(state): BAMCompanyGuide.Model.CompanyResource[] {
    if (Object.keys(state.filters).length === 0) {
      return state.filtered;
    }
    return filterCompanies(state.filters, state.filtered);
  },
  getSubCompanies(state) {
    return state.subCompanies;
  },
  getActiveCompany(state): BAMCompanyGuide.Model.CompanyResource {
    const activeCompanies = state.activeCompanies[state.activeCompanies.length - 1];
    return state.filtered.find((company) => company.data.id === activeCompanies);
  },
  getDivisions(state, getter): IKeyValuePair[] {
    const filteredDivisions = getSortedArrayOfProperty(getter.getCompanies, 'division');
    return filteredDivisions.length ? filteredDivisions : getSortedArrayOfProperty(state.filtered, 'division');
  },
  getCountries(state, getter): IKeyValuePair[] {
    const filteredCountries = getSortedArrayOfProperty(
      getter.getCompanies,
      'visiting_address_country_code',
      'visiting_address_country'
    );
    return filteredCountries.length
      ? filteredCountries
      : getSortedArrayOfProperty(state.filtered, 'visiting_address_country_code', 'visiting_address_country');
  },
  getCitiesByCountry(state, getter): IKeyValuePair[] {
    const filteredCities = getSortedArrayOfProperty(getter.getCompanies, 'visiting_address_city');
    return filteredCities.length ? filteredCities : getSortedArrayOfProperty(state.filtered, 'visiting_address_city');
  },
  getPreviousActiveCompany(state): BAMCompanyGuide.Model.CompanyResource {
    const previousActiveCompanyId = state.activeCompanies[state.activeCompanies.length - 2];
    return state.filtered.find((company) => company.data.id === previousActiveCompanyId);
  },
  getTotalCompanies(state) {
    return state.filtered.length;
  },
};

const getAllCompaniesByParentId = (
  companies: BAMCompanyGuide.Model.CompanyResource[],
  parentId: number
): BAMCompanyGuide.Model.CompanyResource[] => {
  if (parentId === 450) {
    return companies;
  }

  return companies
    .filter((c) => c.data.id === parentId || c.data.parent_id === parentId)
    .map((c) => {
      if (c.data.id === parentId) {
        return c;
      }
      return getAllCompaniesByParentId(companies, c.data.id);
    })
    .reduce<BAMCompanyGuide.Model.CompanyResource[]>((companies, c) => {
      if (Array.isArray(c)) {
        return companies.concat(c);
      }
      companies.push(c);
      return companies;
    }, []);
};

// actions
const actions = <ActionTree<IState, any>>{
  async hydrate({ commit, rootState }) {
    try {
      commit('setAllCompanies', await api.getCompanies(rootState.app.language));
      commit(
        'setCompanies',
        getAllCompaniesByParentId(await api.getCompanies(rootState.app.language), rootState.app.rootCompanyId)
      );
    } catch (err) {
      commit('setError', err);
    }
  },
  async hydrateSubCompanies({ commit, rootState }, companyId) {
    commit('setSubCompaniesLoading', false);
    try {
      commit('setSubCompanies', await api.getSubCompanies(rootState.app.language, companyId));
    } catch (err) {
      commit('setError', err);
    }
  },
  setActiveCompany({ commit }) {
    commit('setShowDetailView', true);
  },
  async hideActiveDetailCompany({ state, commit, rootState }) {
    commit('setSubCompaniesLoading', false);
    commit('setShowDetailView', false);
    commit('removeActiveCompany');
    if (state.activeCompanies.length > 0) {
      commit('toggleDetailView');
      const companyId = state.activeCompanies[state.activeCompanies.length - 1];
      try {
        commit('setSubCompanies', await api.getSubCompanies(rootState.app.language, companyId));
      } catch (err) {
        commit('setError', err);
      }
    }
  },
  setScrollPos({ commit }, position: number) {
    commit('setScrollPos', position);
  },
  showActiveCompany({ commit }) {
    commit('setShowDetailView', true);
  },
  filterCompanies({ commit }, filters: IFilters) {
    commit('setFilters', filters);
  },
  async addActiveCompany({ commit, rootState }, companyId: number) {
    commit('setSubCompaniesLoading', false);
    commit('addActiveCompany', companyId);
    try {
      commit('setSubCompanies', await api.getSubCompanies(rootState.app.language, companyId));
    } catch (err) {
      commit('setError', err);
    }
  },
  resetFilter({ commit }) {
    commit('resetFilter');
  },
  resetSearch({ commit }) {
    commit('resetSearch');
  },
};

// mutations
const mutations = <MutationTree<IState>>{
  setCompanies(state, companies: BAMCompanyGuide.Model.CompanyResource[]) {
    state.filtered = companies;
    state.isLoaded = true;
  },
  setAllCompanies(state, companies: BAMCompanyGuide.Model.CompanyResource[]) {
    state.all = companies;
    state.isLoaded = true;
  },
  setSubCompanies(state, companies: BAMCompanyGuide.Model.CompanyResource[]) {
    state.subCompanies = companies;
    state.subCompaniesLoaded = true;
  },
  setError(state, error: Error | null) {
    state.error = error;
  },
  setFilters(state, filters: IFilters) {
    state.filters = Object.assign(state.filters, filters);
  },
  setSubCompaniesLoading(state, status) {
    state.subCompaniesLoaded = status;
  },
  // could also be in filters, but as they are separate functionality
  // we will specifically set it.
  setRootCompanyId(state, companyId: number) {
    state.rootCompanyId = companyId;
  },
  setScrollPos(state, position: number) {
    state.scrollPos = position;
  },
  setShowDetailView(state, status: boolean) {
    state.showDetailView = status;
  },
  addActiveCompany(state, companyId: number) {
    state.activeCompanies.push(companyId);
    state.showDetailView = true;
  },
  toggleDetailView(state) {
    state.showDetailView = !state.showDetailView;
  },
  removeActiveCompany(state) {
    state.activeCompanies.splice(-1);
  },
  resetFilter(state) {
    state.filters = {
      ...state.filters,
      division: '',
      country: '',
      city: '',
    };
  },
  resetSearch(state) {
    state.filters = {
      ...state.filters,
      search: '',
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
